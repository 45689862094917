<script>

  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import userSearchModelWS  from "@/components/widgets/worksites/userSearchModelWS";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'gestioneTeamDiLavoro',
    props: [
        'type',
        'projectID',
        'worksiteID',
        'taskID',
        'interventionID',
        'data',
    ],
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Gestione Team di Lavoro",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        addTabPlan:false,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
            projectID:this.projectID,
            worksiteID:this.worksiteID,
            startStr:moment().format('YYYY-MM-DD'),
            endStr:"",
            name:"",
            details:"",
        },
        
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 10,
        sortRes:'asc',
        value: 'All',
        n_results:0,
        pages: [],
        responseList: [
        ],
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        minDate:moment().format('YYYY-MM-DD'),
        maxDate:new Date().getFullYear().toString() + '-12-31',
        resultsList:[],
        teamLeaderID:'',
        qSearch:'',
      };
    },
    components: {
   
    },
    validations: {
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      
    },

    methods: {
      convertDatetime(date){
        if (date){
          return  moment(date).format('DD/MM/YYYY HH:MM') 
        }
      },
      len(array){
        if(array){
          return array.length
        } else {
          return 0
        }
      },
      axiosInterceptor(){
        axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                    Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }                
                    return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
      },
      async fullSearch(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}users/search/full/text`,
            { 
            params: { 
                q: this.qSearch,
            },
            headers: { 'authorization':this.tk} 
            }
        )
        .then((response) => {
            if (response.data)  {
            this.resultsList = response.data
            }
        })
      },
      getList(){
        this.axiosInterceptor()
        let urlRef= ''
        if (this.type=='worksite-task' && this.taskID!='') {
          urlRef = `by-taskid/${this.taskID}`
        } else if(this.type=='intervention' && this.interventionID!='') {
          urlRef = `by-interventionid/${this.interventionID}`
        }
         else {
          urlRef = `by-worksiteid/${this.worksiteID}`
        }

        axios.get(`${this.UrlServer}teams/${urlRef}`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.responseList = response.data.results.data;
          this.n_results = response.data.n_results;
          this.paginated = response.data.results
          if (this.n_results==0){
            // Aggiorna assegnazione Attività/Intervento
            let data = {
              "teamLeaderID":null,
              "assignedTo":{}
            }
            if (this.type=='worksite-task') {
              this.updateTask(data)
            }
            if (this.type=='intervention') {
              this.updateInt(data)
            }
          }
        })
      },
      SearchData(){
        this.searchQuery= this.filtersearchQuery1;
        this.filterdate = this.filterdate1;
        this.filtervalue = this.filtervalue1;
      },

      editdata(data) {
        this.$router.push('/worksite/detail/'+data)
      },

      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('exampleModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },
      initItem(params){
        this.axiosInterceptor()
        this.submitted = true;
        this.v$.$touch();

        this.item.taskID = this.data._id,
        this.item.firstName = params.firstName
        this.item.lastName = params.lastName
        this.item.userID = params._id
        this.item.uid = params.uid
        this.item.role = params.roles_permissions.role

        this.item.task_type = 'team-task-cantiere'
        this.item.dateString = this.item.startStr
        this.item.dateTmp = moment(this.item.startStr).unix()
        this.item.year = moment().format('YYYY')
        this.item.month = this.item.dateString.split('-')[1]
        this.item.day =  this.item.dateString.split('-')[2]
        this.item.start = moment(this.item.startStr).valueOf()
        this.item.start_tmp = moment(this.item.startStr).unix()
        this.item.end = moment(this.item.endStr).valueOf()
        this.item.end_tmp = moment(this.item.endStr).unix()
        this.item.createdBy = this.datastore.auth.currentUser

        axios.post(`${this.UrlServer}teams/register`, this.item, {headers: { authorization:this.tk}} )
          .then((response)=>{
            let self = this
            if (response.data) {
              this.resultsList = []
              Swal.fire({  
                title: this.$t("t-msg-team-init") , 
                text: this.$t("t-msg-team-init-content"), 
                icon:"success",              
                showCancelButton:false,
                showConfirmButton:false,
                timer:3000
              }).then(()=>{
                    self.getList()
                    self.$emit('updateTeam')
                    self.qSearch=''
              });
            }
        })
      },
      deletedata(id){
        let self = this
        Swal.fire(
          {
            title: this.$t("t-delete-confirm-setting-title"),
            text: this.$t("t-delete-confirm-setting-text"),
            icon: this.$t("t-delete-confirm-setting-icon"),
            showCancelButton: true,
            cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
            cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
            confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
            confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
          }
          ).then((result) => {
            if (result.value) {
              this.axiosInterceptor()
              axios.delete(`${this.UrlServer}teams/by-worksite/${id}/${this.worksiteID}`, {headers: { authorization:this.tk}} )
                .then(()=>{
                  self.getList() 
                  self.$emit('updateTeam') 
              })
            }
        });
      },
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(responseList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return responseList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        this.getList()
      },
      splitStr(str,car){
            if (str){
            if(str.includes(car)==true) {
                let val =  str.split(car)
                return `${val[0]} ${val[1]}`
            } else{
                return str
            }
            } else {
            return ''
            }
        },
        selectItem(params){
          let arrayUser = this.responseList.filter(function(el){
            return el.userID == params._id
          })
          if (arrayUser.length==0){
            this.initItem(params)
          } else {
            Swal.fire({
              title:"Utente già presente",
              text:"L'utente che hai selezionato è già presente nel Team!",
              icon:"info",
              showCancelButton:false,
              showConfirmButton:false,
              timer:3000,
            }) 
          }
        },
        updateTeamLeader(){
         
          let data = false
          if (this.teamLeaderID.length==24)
          {
            data = {
              taskID: this.data._id,
              worksiteID: this.worksiteID,
              status:'assigned',
            }
            this.axiosInterceptor()
            axios.put(`${this.UrlServer}teams/set-teamleader/${this.teamLeaderID}`, data, {headers: { authorization:this.tk}} )
            .then((response)=>{
              if (response.data) {
                this.$emit('updateTeam') 
              }
            })
          }

        },
        updateTask(data){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}tasks/${this.taskID}`, data, {headers: { authorization:this.tk}})
          .then(() => {
            this.$emit('updateTeam') 
          })
        },
        updateInt(data){
          this.axiosInterceptor()
          axios.put(`${this.UrlServer}intervention-reports/${this.interventionID}`, data, {headers: { authorization:this.tk}})
          .then(() => {
            this.$emit('updateTeam') 
          })
        },
    },
    mounted() {
      if (this.data) {
        if(!this.data.teamLeaderID){
          this.teamLeaderID = ''
        } else {
          this.teamLeaderID = this.data.teamLeaderID
        }
      }
      this.getList()
    }
  };
</script>

<template>

    <div class="row ">
        <div class="col-lg-12">         
            <div class="card-body pl-10 mb-20">
                <template v-if="addTabPlan==false">
                    <button class="btn btn-primary" type="button" @click="addTabPlan=true">
                        <i class="bx bx-search"></i>
                        <i class="bx bx-group"></i>
                       Ricerca utente
                    </button>  
                </template>
                <template v-else>
                    <button class="btn btn-light" @click="addTabPlan=false">
                        X
                        Chiudi scheda Team
                    </button> 
                </template>

            </div>
        </div>
    </div>
    <div v-if="addTabPlan">
        <div class="row background-btn-grey-1  mb-50 mt-20 pl-10" >
            <div class="col-md-12 col-lg-12">
                
                <div class="mb-3 form-icon">
                    
                    <input
                        type="text"
                        id="search"
                        v-model="qSearch"
                        placeholder="Digita qui termine di ricerca dell'utente..."
                        class="form-control-search p-3 mt-20 mb-0.5 w-full border border-blue-300 rounded"
                        @input="fullSearch()"
                    >

                            <ul
                              v-if=" len(resultsList) > 0"
                              class="w-full rounded bg-white border border-gray-300 px-4 py-2 space-y-1 absolute z-10"
                            >
                              <li class="px-1 pt-1 pb-2 font-bold border-b border-gray-200">
                                 {{ len(resultsList) }} 
                                <template v-if="len(resultsList)>1">
                                  risultati
                                </template>
                                <template v-else>
                                  risultato
                                </template>  
                                 
                              
                                </li>
                              <li
                                  v-for="item in resultsList"
                                  :key="item._id"
                                  @click="selectItem(item)"
                                  class="cursor-pointer hover:bg-gray-100 p-1"
                              >
                              <strong>{{ item.firstName }} {{ item.lastName }}</strong>
                              <template v-if="item.roles_permissions">
                                - Ruolo: {{ item.roles_permissions.name }}
                              </template>
                            
                              </li>
                            </ul>

                        
                              
                </div>
            </div>
        </div>

    </div>
    <div class="card-header  mb-50">
              <div class="row align-items-center mb-50">
               
                <div class="col ">
                  <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#productnav-all" role="tab">
                        Operatori Team
                        <span class="badge badge-soft-danger align-middle rounded-pill ms-1">{{n_results}}</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col-auto">
                  <div id="selection-element">

                  </div>
                </div>
              </div>
            </div>
            <!-- end card header -->
          <div class="card-body" v-if="n_results>0">
            
            <div class="table-card mb-1">
                <div class="row thRow  ">

                    <div class="col text-left">
                        Nome
                    </div>
                    <div class="col text-left">
                        Ruolo
                    </div>
                
                    <div class="col text-left">
                        Azioni
                    </div>
                </div>
                <div class="row list tRow form-check-all d-flex justify-content-end " v-for=" (item, index) of responseList" :key="index">

                    <div class="col text-left">
                      {{ item.firstName }}  {{ item.lastName }}
                       
                    </div>
                    <div class="col text-left">
                       
                        {{ item.role }}
                        
                    </div>

                    <div class="col">
                        <ul class="list-inline text-left">
                
                            <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Rimuovi">
                              <a href="javascript:void(0)" class="text-danger d-inline-block remove-item-btn" @click="deletedata(item._id)">
                                <i class="ri-delete-bin-5-fill fs-16"></i>
                              </a>
                            </li>
                        </ul>
                    </div>
                </div>
          </div>

            <div>
              <div class="table-responsive table-card mb-1">

                <div class="noresult" style="display: none">
                  <div class="text-center">
                    <lottie class="avatar-xl" colors="primary:#121331,secondary:#08a88a" :options="defaultOptions"
                      :height="75" :width="75" />
                    <h5 class="mt-2">{{ $t("t-msg-sorry-no-result") }}</h5>
                    <p class="text-muted mb-0">
                     
                    </p>
                  </div>
                </div>
              </div>
              <div class="d-flex tRow justify-content-end mt-3" v-if="n_results > perPage">        
                    <div class="col-lg-3 col-md-12 ">
                        <label class="pt-2">Risultati per pagina:</label>
                        <div class="pl-10 pr-20">
                        
                        <select class="form-control" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                        <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }" v-for="index in paginated.total_pages" :key="index">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                        
                        </ul>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
            </div>
          </div>
    

    <div class="row" v-if="n_results>0">
        <div class="col-lg-12">            
            <div class="card-body">
              <div class="row thRow  ">
                <div class="col-lg-12 col-md-12 text-left">
                  Caposquadra (team leader)
                </div>
              </div>
              <div class="row thRow  ">
                <div class="col-lg-12 col-md-12 text-left">
                  <select class="form-select" v-model="teamLeaderID" @change="updateTeamLeader">
                    <option value="">Seleziona Caposquadra</option>
                    <option v-for=" (item, index) of responseList" :key="index" :value="item._id">{{ item.firstName }} {{ item.lastName }}</option>
                  </select>
                </div>
              </div>
              
            </div>
        </div>
    </div>

</template>